import { useEffect, useState } from "react"
import axios from "axios"
import { useStateValue } from "../state"

export default function useLike(id) {
  const [amount, setAmout] = useState(-1)
  const [state, dispatch] = useStateValue()

  const { posting, id: postID, shouldDislike, needsUpdate } = state.like

  const fetchLikes = (callback = false) => {
    axios
      .get(`${process.env.GATSBY_API_URL}/api/posts/${id}/like`)
      .then(({ data: { likes } }) => {
        setAmout(likes)
        callback && callback()
      })
  }

  useEffect(() => {
    fetchLikes()
  }, [id])

  useEffect(() => {
    if (id === postID) {
      return () => dispatch({ type: "LIKE_RESET" })
    }
  }, [id, postID, dispatch])

  useEffect(() => {
    id === postID &&
      needsUpdate &&
      fetchLikes(() => dispatch({ type: "LIKE_HAS_UPDATED" }))
  }, [needsUpdate, id, postID, dispatch])

  useEffect(() => {
    if (posting && id === postID) {
      axios
        .post(`${process.env.GATSBY_API_URL}/api/posts/${id}/like`, {
          shouldDislike,
        })
        .then(() => {
          dispatch({ type: "LIKE_POSTED" })
          dispatch({ type: "LIKE_SHOULD_UPDATE" })
        })
    }
  }, [dispatch, posting, shouldDislike, id, postID])

  return [amount, setAmout]
}
