import styled, { css } from "styled-components"
import Icon from "../Icon"
import { colors, breakpoints } from "../Style"

export const StyledLike = styled.span`
  ${props =>
    props.inlist
      ? css`
          display: inline-block;
          color: ${colors.white};
          font-size: 1.6rem;

          @media ${breakpoints.medium} {
            font-size: 2rem;
          }
        `
      : css`
          display: flex;
          flex: row nowrap;
          align-items: baseline;
          margin-left: 1rem;
          color: ${colors.yellow};
          font-size: 2rem;

          @media ${breakpoints.medium} {
            font-size: 2.4rem;
          }
        `}
`

export const StyledIcon = styled(Icon)`
  position: relative;
  vertical-align: middle;

  ${props =>
    props.inlist
      ? css`
          top: -4px;
          width: 1.7rem;
          height: 1.8rem;
          margin-left: 0.2rem;

          @media ${breakpoints.medium} {
            top: -3px;
            width: 2rem;
            height: 2.1rem;
          }
        `
      : css`
          top: 0.2rem;
          width: 2rem;
          height: 2.1rem;
          margin-left: 0.6rem;

          @media ${breakpoints.medium} {
            width: 2.6rem;
            height: 2.5rem;
          }
        `}
`
