import React, { useState } from "react"
import shouldDislike from "../../helpers/shouldDislike"
import ListData from "../listdata"
import { ButtonSlim, LikeButton } from "../button"
import Share from "../share"
import Copy from "../copy"
import { Title, Description, List, Actions } from "./Style"
import { Year, Model } from "../card/Style"
import { useStateValue } from "../../state"

export default function Post({ post, location }) {
  const {
    id,
    term,
    model_name,
    description,
    city,
    use_case,
    other_use_case,
    year,
    nick_name,
    images,
    company,
  } = post

  const [, dispatch] = useStateValue()
  const [willShare, setWillShare] = useState(false)

  return (
    <>
      <Title>
        {model_name && model_name.title} <Model>{term}</Model>
        {`"${nick_name}" `}
        {year !== 0 && <Year>{year}</Year>}
      </Title>
      <Description>{description}</Description>
      <List
        as={ListData}
        id={id}
        wide
        data={[
          company && { label: "Företag", value: company },
          city && { label: "Ort", value: city },
          other_use_case
            ? { label: "Användningsområde", value: other_use_case }
            : use_case && { label: "Användningsområde", value: use_case.name },
        ]}
      />
      <Actions>
        <LikeButton
          onClick={() =>
            dispatch({
              type: "LIKE_POST",
              payload: {
                id: id,
                shouldDislike: shouldDislike(id),
              },
            })
          }
        />
        <ButtonSlim
          invert
          icon={["fal", "share"]}
          onClick={() => setWillShare(!willShare)}
        >
          Dela
        </ButtonSlim>
      </Actions>
      {willShare && (
        <Share
          url={location.href}
          media={images[0].path}
          title={`Kolla! Har du sett den här? ${model_name &&
            model_name.title} ${term} ${year}`}
        />
      )}
      <Copy href={location.href} />
    </>
  )
}
