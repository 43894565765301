import React from "react"
import SEO from "../components/seo"
import Hero from "../components/hero"
import PostCard from "../components/post"
import Posts from "../components/posts"

const Post = ({ pageContext, location, ...props }) => {
  const { post } = pageContext
  const { model_name, term, year, images, id } = post

  return (
    <>
      <SEO
        title={`${post.model_name.title} ${term}, ${year}`}
        meta={[
          {
            name: `og:image`,
            content: images.length > 0 && images[0].path,
          },
        ]}
      />
      <Hero image={images.length > 0 && images[0].path}>
        <PostCard post={post} location={location} />
      </Hero>
      <Posts
        title={`Senaste ${model_name.title}`}
        models={pageContext.posts}
        model={model_name.id}
        exclude={id}
      />
    </>
  )
}

export default Post
