const shouldDislike = (id) => {
   const likedPostsStorage = localStorage.getItem('likedPosts');
   
   if(!likedPostsStorage) {
        localStorage.setItem('likedPosts', JSON.stringify({
            [id]: true
        }))
        return false;
   }
   
   const likedPosts = JSON.parse(likedPostsStorage);

   if(likedPosts[id]) {
       delete likedPosts[id];
       localStorage.setItem('likedPosts', JSON.stringify(likedPosts))
       return true;
   } else {
       likedPosts[id] = true;
       localStorage.setItem('likedPosts', JSON.stringify(likedPosts))
       return false;
   }
}

export default shouldDislike;