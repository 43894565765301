import React from "react"
import useLike from "../../lib/useLike"
import { StyledIcon, StyledLike } from "./Style"

export default function Like({ id, inlist, ...props }) {
  const [amount] = useLike(id)

  return (
    amount > -1 && (
      <StyledLike {...props} inlist={inlist}>
        {amount} <StyledIcon inlist={inlist} icon={["fal", "thumbs-up"]} />
      </StyledLike>
    )
  )
}
