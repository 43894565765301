import styled from "styled-components"
import { sidePadding } from "../Style"

export const Container = styled.div`
  margin: 0 auto ${sidePadding}rem;
  text-align: center;

  button {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
