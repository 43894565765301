import React, { useState, Fragment } from "react"
import Card from "../card/"
import Button from "../button"
import Insert from "../../lib/useAbout"
import useMobile from "../../lib/useMobile"
import { Section, Title, Container, Footer } from "./Style"

export default function Posts({
  title,
  model,
  models = [],
  exclude = -1,
  insert = false,
}) {
  const [isMobile] = useMobile()
  const [posts] = useState(
    model && exclude > -1
      ? models
          .filter(m => exclude > -1 && m.id !== exclude)
          .filter(m => model && m.model_name.id === model)
      : models
  )
  const initialRange = 9
  const [range, setRange] = useState(initialRange)

  return (
    posts.length > 0 && (
      <Section>
        {title && <Title as="h2">{title}</Title>}
        <Container>
          {posts.slice(0, range).map((props, index) => (
            <Fragment key={"i-" + index}>
              {isMobile && insert && index === 0 && <Insert key={"insert"} />}
              <div>
                <Card {...props} />
              </div>
              {!isMobile && insert && index === 1 && <Insert key={"insert"} />}
            </Fragment>
          ))}
        </Container>
        {posts.slice(0, range).length < posts.length && (
          <Footer>
            <Button onClick={() => setRange(range + range)}>
              Ladda fler +
            </Button>
          </Footer>
        )}
      </Section>
    )
  )
}
