import React from "react"
import { Row, Item, Label } from "./Style"
import Like from "../like"

export default function ListData({ id, data, likes, wide = false, ...props }) {
  return (
    <dl {...props}>
      {data.map(
        (row, index) =>
          row && (
            <Row key={"ld-" + index} wide={wide}>
              <Label as="dt">{row.label}:</Label>
              <Item as="dd">{row.value}</Item>
            </Row>
          )
      )}
      {id ? (
        <Row wide={wide}>
          <Label as="dt">Gillar:</Label>
          <Item as="dd">
            <Like id={id} inlist={"true"} />
          </Item>
        </Row>
      ) : null}
    </dl>
  )
}
