import styled from "styled-components"
import { BtnReset } from "../button/Style"
import { colors, fontFamily, sidePadding, easings, breakpoints } from "../Style"

export const URL = styled(BtnReset)`
  padding: 0;
  color: ${colors.white};
  font-family: ${fontFamily.primary};
  font-size: 1.4rem;
  font-weight: 400;
  background-color: transparent;
  text-align: left;
  transition: color 0.2s ${easings.appleOut};

  &:hover {
    color: ${colors.yellow};
  }

  @media ${breakpoints.small} {
    text-align: center;
  }
`

export const Label = styled.span`
  display: block;
  margin-right: ${sidePadding}rem;
  color: ${colors.gray["300"]};

  @media ${breakpoints.small} {
    display: inline-block;
  }
`
