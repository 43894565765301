import React from "react"
import {
  StyledCard,
  Content,
  Header,
  Title,
  Brand,
  Model,
  Year,
  Footer,
  ImgContainer,
} from "./Style"
import ListData from "../listdata/"
import Like from "../like/"
import Placeholder from "../Placeholder"
import { NoStateLink as Link } from "../Link"

export default function Card({
  rating,
  model_name,
  term,
  year,
  id,
  images,
  city,
  use_case,
  other_use_case,
  nick_name,
  company,
}) {
  return (
    <StyledCard to={"/post/" + id}>
      <ImgContainer>
        {images.length ? (
          <img
            src={images[0].path}
            alt={`${model_name.title}, ${term} (${year})`}
          />
        ) : (
          <Placeholder />
        )}
      </ImgContainer>
      <Content>
        <Header>
          <Title>
            <Brand>{model_name.title}</Brand> <Model>{term}</Model>
            {`"${nick_name}" `}
            <Year>{year}</Year>
          </Title>
          <Like id={id} />
        </Header>
        <ListData
          data={[
            company && { label: "Företag", value: company },
            city && { label: "Ort", value: city },
            other_use_case
              ? { label: "Användningsområde", value: other_use_case }
              : use_case && { label: "Användningsområde", value: use_case.name },
          ]}
        />
        <Footer>
          <Link as="span">Läs vidare</Link>
        </Footer>
      </Content>
    </StyledCard>
  )
}
