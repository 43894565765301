import styled from "styled-components"
import { sidePadding, breakpoints } from "../Style"

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: ${props => (props.wide ? `${sidePadding}rem` : 0)};
  }
`

export const Item = styled.p`
  font-size: 1.6rem;
  line-height: 1.22;

  @media ${breakpoints.medium} {
    font-size: 2rem;
    font-weight: 500;
  }
`

export const Label = styled(Item)`
  flex: 0 0 6rem;
  padding-right: 1rem;

  @media ${breakpoints.medium} {
    flex-basis: 7.5rem;
  }
`
