import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import React from "react"
import { Container } from "./Style"

const Share = ({ url, title, media }) => {
  const size = 40

  return (
    <Container>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={size} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={url} appid="521270401588372">
        <FacebookMessengerIcon size={size} round />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={size} round />
      </TwitterShareButton>
      <PinterestShareButton url={url} media={media}>
        <PinterestIcon size={size} round />
      </PinterestShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={size} round />
      </LinkedinShareButton>
      <EmailShareButton url={url} subject={title}>
        <EmailIcon size={size} round />
      </EmailShareButton>
    </Container>
  )
}

export default Share
