import React from "react"
import { URL, Label } from "./Style"

export default function Copy({ href, ...props }) {
  return (
    <URL onClick={() => navigator.clipboard.writeText(href)}>
      <Label>Kopiera länk</Label>
      {href}
    </URL>
  )
}
