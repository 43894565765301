import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Insert from "../components/Insert"

export default () => {
  const data = useStaticQuery(graphql`
    query AboutEpiroc {
      contentfulAboutEpiroc {
        link
        title
        description {
          description
        }
      }
    }
  `)

  return (
    <Insert
      link={data.contentfulAboutEpiroc.link}
      title={data.contentfulAboutEpiroc.title}
      description={data.contentfulAboutEpiroc.description.description}
    />
  )
}
