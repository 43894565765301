import React from "react"
import styled from "styled-components"
import Link from "./Link"
import { colors, sidePadding, breakpoints } from "./Style"
import {Content, Header, ImgContainer, StyledCard} from "./card/Style";
import Placeholder from "./Placeholder";
import {graphql, useStaticQuery} from "gatsby";

const Container = styled.article`
  width: 100%;
  padding: 2.5rem ${sidePadding}rem 2.3rem;
  color: ${colors.complementary.blue};
  background-color: ${colors.yellow};

  @media ${breakpoints.medium} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4.2rem 4.2rem 4.5rem;
  }
`
const Title = styled.h1`
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.25;
  color: ${colors.complementary.blue};

  @media ${breakpoints.medium} {
    margin-bottom: 2rem;
    font-size: 3.2rem;
    line-height: 1.08;
  }
`
const Description = styled.p`
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  line-height: 1.2;
  color: ${colors.complementary.blue};
`

const Footer = styled.div`
  text-align: right;
`

const Card = styled(StyledCard)`
  background: inherit;
  background-color: ${colors.yellow};
  box-shadow: inherit;
  
  &:hover,
  &:focus {
    background-color: ${colors.yellow};
  }
`

const CardImgContainer = styled(ImgContainer)`
  img {
    filter: grayscale(0);
  }
`

export default function Insert({ title, description, link }) {
  const data = useStaticQuery(graphql`
    query picture {
      file(relativePath: { eq: "tshirt_keps.jpg" }) {
        id
        publicURL
      }
    }
  `)

  return (
    <Card to={"/addMachine"}>
      <CardImgContainer>
        {data.file ? (
          <img
            src={data.file.publicURL}
            alt=""
          />
        ) : (
          <Placeholder />
        )}
      </CardImgContainer>
      <Content>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Description>{description}</Description>
        <Footer>
          <Link to="/addMachine" icon={true} dark={true}>
            Lägg till maskin
          </Link>
        </Footer>
      </Content>
    </Card>
  )
}
